<template>
  <div
    class="slide-in-container"
    :class="{'active' : show === true}"
  >
    <div class="slide-in-bg" />
    <div class="slide-in-content">
      <div class="flex justify-between">
        <div>
          <h1 class="text-xl">
            Add your bank details
          </h1>
        </div>
        <div>
          <div
            class="close"
            @click="cancel"
          />
        </div>
      </div>
      <p class="py-5">
        There are no bank details in the invoice. Add your bank details below or click cancel to send an invoice without bank details.
      </p>
      <div>
        <div
          v-show="this.invoiceFooterPayment.length > 0"
          class="flex flex-wrap mb-4"
        >
          <div class="w-4/12">
            Label
          </div>
          <div class="w-7/12">
            <div class="ml-5">
              Value
            </div>
          </div>
          <div class="w-1/12" />
        </div>
        <div
          v-for="(item, index) in invoiceFooterPayment"
          :index="index"
        >
          <div
            class="flex flex-wrap mb-4"
          >
            <div class="w-4/12">
              <input
                v-model="item.reference"
                type="text"
                class="w-full"
              >
            </div>
            <div class="w-7/12">
              <input
                v-model="item.value"
                type="text"
                class="w-full ml-3 mr-3"
              >
            </div>
            <div class="w-1/12">
              <img
                class="cursor-pointer absolute pin-r pin-t z-10 mt-3 mr-1"
                src="@/assets/img/icons/delete.svg"
                alt="Delete"
                width="17"
                @click="deleteFooterPayment(index)"
              >
            </div>
          </div>
          <hr class="mb-4">
        </div>
        <div
          class="text-green underline mt-5 cursor-pointer"
          @click="addFooterPayment"
        >
          + Add bank information to the footer
        </div>
      </div>
    </div>
    <div
      class="actions"
    >
      <div
        v-if="!loading"
        class="flex"
      >
        <div>
          <v-button
            class="btn--secondary"
            @click="cancel"
          >
            Cancel
          </v-button>
        </div>
        <div class="pl-4">
          <v-button
            class="green-btn"
            @click="save"
          >
            Save
          </v-button>
        </div>
      </div>
      <div
        v-else
        class="text-center"
      >
        <img
          src="@/assets/img/icons/loader.svg"
          width="40"
        >
      </div>
    </div>
  </div>
</template>
<script>
import auth from '@/auth'
import { logException } from '@/helpers'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      loading: false,
      currencies: {},
      selectedCurrency: null,
      multiCurrencies: 0,
      invoiceValidPeriod: 0,
      proposalValidPeriod: 0,
      numberPrefix: '',
      invoiceFooterPayment: [],
    }
  },
  async mounted(){
    await this.refresh()
  },
  methods: {
    cancel(){
      this.$emit('close')
    },
    deleteFooterPayment(index){
      this.invoiceFooterPayment.splice(index, 1)
    },
    addFooterPayment(){
      this.invoiceFooterPayment.push({
        id: new Date().getTime(),
        key: 'invoice_footer_payment',
        value: null,
        reference: null,
      })
    },
    async refresh() {
        try {
          await this.$store.dispatch('company/refreshCompanyDetails')

          const company = this.$store.getters['company/details']
          this.selectedCurrency = company.currency.id
          this.multiCurrencies = (company.multiCurrencies) ? Number(company.multiCurrencies) : 0
          this.invoiceValidPeriod = company.invoiceValidPeriod
          this.proposalValidPeriod = company.proposalValidPeriod
          this.numberPrefix = company.numberPrefix
          this.invoiceFooterPayment = []
          if(company.invoiceFooterPayment){
            const settings = Object.values(company.invoiceFooterPayment)
            for (const setting of settings) {
              this.invoiceFooterPayment.push({
               id: setting.id,
               key: 'invoice_footer_payment',
               value: setting.value,
               reference: setting.reference,
              })
            }
          }

          auth.refreshUser()

        } catch(e) {
          this.$toasted.global.api_error(e)
        }
      },
    async save(){

      this.loading = true

      if(this.invoiceFooterPayment.length < 1){
        this.loading = false
        this.$toasted.global.general_error({
          message : 'Please add at least one field.'
        })
        return false
      }
      for (const setting of this.invoiceFooterPayment) {
        if((setting.value == '' || setting.value == null) || (setting.reference == '' || setting.reference == null)){
          this.loading = false
          this.$toasted.global.general_error({
            message : 'The field label and value cannot be empty.'
          })
          return false
        }
      }

      const postData = {
          selectedCurrency: this.selectedCurrency,
          invoiceValidPeriod: this.invoiceValidPeriod,
          proposalValidPeriod: this.proposalValidPeriod,
          multiCurrencies: this.multiCurrencies,
          numberPrefix: this.numberPrefix,
          invoiceFooterPayment: this.invoiceFooterPayment,
        }

      try {
        await this.$store.dispatch('company/patchInvoice', postData)
        this.$emit('close')
        setTimeout(() => this.loading = false, 300)
      } catch(e) {
        this.$toasted.global.api_error(e)
      }
    }
  }
}
</script>
